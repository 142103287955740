import React from "react";
import { sendEvent } from "./messaging";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Click any of the below buttons to test inter-window messaging.</p>
        <a
          className="App-link"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            sendEvent("payment-received", { reference: "tx_abcdef0123456789" });
          }}
        >
          Success
        </a>
        <a
          className="App-link"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            sendEvent("payment-failed");
          }}
        >
          Failure
        </a>
      </header>
    </div>
  );
}

export default App;
